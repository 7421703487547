import { GoPlus } from '@react-icons/all-files/go/GoPlus'
import { MdOutlineQrCodeScanner } from '@react-icons/all-files/md/MdOutlineQrCodeScanner'
import { IChevronDown } from 'consts/Icons'
import ROUTES from 'consts/Routes'
import { BOOK_A_MEETING_WITH_FOUNDERS_URL } from 'consts/Urls'
import { getBaseUrlFor } from 'utils/Url'

const HEADER = {
  APP_USER_NAVIGATION: [
    // {
    //   title: 'Host Events',
    //   path: ROUTES.HOSTING.HOST_YOUR_EVENT,
    //   className: 'hidden lg:block',
    // },
    // {
    //   title: 'Explore',
    //   path: ROUTES.EXPLORE,
    //   className: 'hidden lg:block',
    // },
    // {
    //   title: 'Tickets',
    //   path: ROUTES.USER_TICKETS,
    //   className: 'hidden lg:block',
    // },
    // {
    //   path: '',
    //   title: 'Separator',
    //   className: 'hidden lg:block',
    // },
    // {
    //   title: '',
    //   path: ROUTES.DASHBOARD,
    //   className: 'btn btn-sm btn-secondary',
    //   icon: (
    //     <BsArrowLeftRight
    //       size={16}
    //       className='custom-transition-a cursor-pointer hover:fill-secondary'
    //     />
    //   ),
    // },
  ],
  APP_HOST_NAVIGATION: [
    {
      // title: 'Scan',
      path: ROUTES.SCAN,
      icon: (
        <MdOutlineQrCodeScanner
          size={28}
          className='custom-transition-a cursor-pointer hover:fill-secondary'
        />
      ),
      className: 'hidden md:block',
    },
    {
      title: 'Add',
      path: '#',
      icon: <GoPlus size={16} />,
      className: 'hidden md:block btn btn-sm btn-primary gap-2 flex flex-row',
      subMenu: [
        {
          title: 'New Event',
          path: ROUTES.EVENT_NEW,
        },
        {
          title: 'New Venue',
          path: ROUTES.VENUE_ADD,
        },
        {
          title: 'New Discount(Coming Soon)',
          // path: ROUTES.FEATURES.ATTENDEE_MANAGEMENT,
          path: '#',
        },
        {
          title: 'Membership(Coming Soon)',
          // path: ROUTES.MEMBERSHIP_NEW,
          path: '#',
        },
        {
          title: 'Physical Products(Coming Soon)',
          // path: ROUTES.PRODUCT_NEW,
          path: '#',
        },
      ],
    },

    // {
    //   // title: 'Notificatopm',
    //   path: ROUTES.DASHBOARD,
    //   icon: (
    //     <AiFillNotification
    //       size={24}
    //       className='custom-transition-a cursor-pointer hover:fill-secondary'
    //     />
    //   ),
    // },
    // {
    //   title: '',
    //   path: ROUTES.USER_DASHBOARD,
    //   className: 'btn btn-sm btn-secondary',
    //   icon: (
    //     <BsArrowLeftRight
    //       size={16}
    //       className='custom-transition-a cursor-pointer hover:fill-secondary'
    //     />
    //   ),
    // },
  ],
  NAVIGATION: [
    // {
    //   title: 'Explore',
    //   path: ROUTES.EXPLORE,
    //   className: 'hidden lg:block',
    // },
    {
      title: 'About',
      path: ROUTES.ABOUT_US,
      className: 'hidden lg:block btn btn-sm btn-ghost hover:bg-base-200 custom-transition-b',
    },
    {
      title: 'Pricing',
      path: ROUTES.FEATURES.PRICING_COMPARISON,
      className: 'hidden lg:block btn btn-sm btn-ghost hover:bg-base-200 custom-transition-b',
    },
    {
      title: 'FAQs',
      path: ROUTES.FAQS,
      className: 'hidden lg:block btn btn-sm btn-ghost hover:bg-base-200 custom-transition-b',
    },
    // {
    //   title: 'Help',
    //   path: ROUTES.RESOURCES.HELP,
    //   className: 'hidden lg:block btn btn-sm btn-ghost hover:bg-base-200 custom-transition-b',
    // },
    {
      title: 'Blog',
      // path: ROUTES.RESOURCES.BLOG,
      externalPath: getBaseUrlFor('marketplace') + ROUTES.RESOURCES.BLOG,
      className: 'hidden lg:block btn btn-sm btn-ghost hover:bg-base-200 custom-transition-b',
    },
    {
      title: 'Features',
      path: '#',
      className: 'hidden lg:block',
      icon: <IChevronDown size={16} />,
      subMenu: [
        {
          title: 'Discover',
          path: ROUTES.FEATURES.DISCOVER,
        },
        {
          title: 'Ticketing',
          path: ROUTES.FEATURES.TICKETING,
        },
        {
          title: 'Attendee Management',
          path: ROUTES.FEATURES.ATTENDEE_MANAGEMENT,
        },
        {
          title: 'Growth & Marketing',
          path: ROUTES.FEATURES.GROWTH_AND_MARKETING,
        },
      ],
    },
    {
      title: 'Book a Meeting',
      // path: ROUTES.LOG_IN,
      externalPath: BOOK_A_MEETING_WITH_FOUNDERS_URL,
      className: 'hidden lg:block btn btn-sm btn-ghost hover:bg-base-200 custom-transition-b',
    },
    {
      title: 'Sign Up',
      path: ROUTES.SIGN_UP,
      className: 'hidden lg:block btn btn-sm btn-secondary',
    },
    // {
    //   title: 'Log In',
    //   path: ROUTES.LOG_IN,
    //   className: 'hidden lg:block btn btn-sm btn-primary',
    // },
  ],

  NEW_ITEMS: [
    'New Event',
    'New Venue',
    'New Discount(Coming Soon)',
    'Membership(Coming Soon)',
    'Physical Products(Coming Soon)',
  ],
}

export default HEADER
